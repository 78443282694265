import React, { Suspense, lazy, Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from './components/section-components/ScrollToTop';
import Privacydata from './data/privacydata.json';
import Termdata from './data/termdata.json';
import Refunddata from './data/refunddata.json';


const Faq = lazy(() => import('./components/faq'));
const Privacy = lazy(() => import('./components/privacy'));
const Refund = lazy(() => import('./components/refund'));
const Term = lazy(() => import('./components/term'));
const Contact = lazy(() => import('./components/contact'));
const Login = lazy(() => import('./components/login'));
const Certificate = lazy(() => import('./components/certificate'));
const CertificateForm = lazy(() => import('./components/certificateForm'));
const Register = lazy(() => import('./components/register'));
const Signintest = lazy(() => import('./components/signintest'));
const Signuptest = lazy(() => import('./components/signuptest'));
const ForgetPassword = lazy(() => import('./components/forgetPassword'));

const eltac = lazy(() => import('./components/eltac'));
const TestFormatEltac = lazy(() => import('./components/eltac-test-format'));
const TestFormatEltacSpW = lazy(() => import('./components/eltac-about-part-writing-speaking'));
const TestFormatEltacRead = lazy(() => import('./components/eltac-about-part-reading'));
const TestFormatEltacListening = lazy(() => import('./components/eltac-about-part-listening'));
const TestFormatEltacWriting = lazy(() => import('./components/eltac-about-part-writing'));
const PreparationEltac = lazy(() => import('./components/preparation-eltac'));
const ScoringEltac = lazy(() => import('./components/scoring-eltac'));



class Root extends Component {
    render() {
        return (
            <Router>
                <div>
                    <ScrollToTop />
                    <Suspense fallback={<div></div>}>
                        <Switch>
                            <Route exact path="/" component={eltac} />
                            <Route path="/privacy-policy" render={() => <Privacy data={Privacydata} />} />
                            <Route path="/terms-of-use" render={() => <Term data={Termdata} />} />
                            <Route path="/refund-policy" render={() => <Refund data={Refunddata} />} />
                            <Route path="/contact-us" component={Contact} />
                            <Route path="/signin" component={Login} />
                            <Route path="/signup" component={Register} />
                            <Route path="/signintest" component={Signintest} />
                            <Route path="/signuptest" component={Signuptest} />
                            <Route path="/faq-page" component={Faq} />
                            <Route path="/forgot-password" component={ForgetPassword} />
                            <Route path="/verify/:id" component={Certificate} />
                            <Route path="/verify" component={CertificateForm} />

                         

                            {/* ELTAC */}
                            
                            <Route path="/eltac-test-format" component={TestFormatEltac} />
                            <Route path="/test-format-eltac-speaking" component={TestFormatEltacSpW} />
                            <Route path="/test-format-eltac-reading" component={TestFormatEltacRead} />
                            <Route path="/test-format-eltac-listening" component={TestFormatEltacListening} />
                            <Route path="/test-format-eltac-writing" component={TestFormatEltacWriting} />
                            <Route path="/preparation-eltac" component={PreparationEltac} />
                            <Route path="/scoring-eltac" component={ScoringEltac} />
                            <Route path="/scores" component={ScoringEltac} />

                        </Switch>
                    </Suspense>
                </div>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('riyaqas'));
